import Http from '@/services/http';
import { fetchRecordList } from '@/api/record';
import { RECORD_TYPE } from '@/api/constants';

function getRecordTypeUrl (recordType, urls) {
  if (urls[recordType]) {
    return urls[recordType];
  }
  throw new Error('Wrong record type set: ' + recordType);
}

export function executeSearch (recordType, search) {
  const path = getRecordTypeUrl(recordType, {
    [RECORD_TYPE.SEARCH_FOR_APPLICANTS]: '/applicant-search/execute',
    [RECORD_TYPE.SEARCH_FOR_COMPANIES]: '/company-search/execute',
  });
  return Http.post(path, search);
}

export function getSearchList (recordType) {
  return fetchRecordList(recordType);
}

export function getSearch (recordType, searchId) {
  const path = getRecordTypeUrl(recordType, {
    [RECORD_TYPE.SEARCH_FOR_APPLICANTS]: `/applicant-search/pattern/${searchId}`,
    [RECORD_TYPE.SEARCH_FOR_COMPANIES]: `/company-search/pattern/${searchId}`,
  });
  return Http.get(path);
}

export function saveSearch (recordType, search) {
  let path = getRecordTypeUrl(recordType, {
    [RECORD_TYPE.APPLICANT]: '/applicant-search/pattern',
    [RECORD_TYPE.COMPANY]: '/company-search/pattern',
  });
  if (search.id) {
    path += '/' + search.id;
    return Http.post(path, search);
  } else {
    return Http.put(path, search);
  }
}

export function deleteSearch (recordType, searchId) {
  const path = getRecordTypeUrl(recordType, {
    [RECORD_TYPE.APPLICANT]: `/applicant-search/pattern/${searchId}`,
    [RECORD_TYPE.COMPANY]: `/company-search/pattern/${searchId}`,
  });
  return Http.delete(path);
}
/* Currently not used
export function executeSearchById (recordType, searchId) {
  let path = getRecordTypeUrl(recordType, {
    [RECORD_TYPE.APPLICANT]: `/applicant-search/execute/${searchId}`,
    [RECORD_TYPE.COMPANY]: `/company-search/execute/${searchId}`,
  });
  return Http.get(path);
} */
