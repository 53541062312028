import Http from '@/services/http';
import { fetchRecordList, fetchRecord } from '@/api/record';
import { RECORD_TYPE } from '@/api/constants';

export function getOwnCompanyProfile () {
  return Http.get('/company-profile');
}

export function setOwnCompanyProfile (profile) {
  return Http.post('/company-profile', profile);
}

export function getOwnCompanyProfileStudyInterests () {
  return Http.get('/company-profile/available-study-interests');
}

export function removeCompanyBookmark (recordId, studyInterestId) {
  const path = `/company-bookmarks/bookmark/${recordId}/${studyInterestId}`;
  return Http.delete(path);
}

export function downloadCompanyBookmarksPdf (fileName) {
  return Http.download('/company-bookmarks/bookmarks.pdf', fileName);
}
// ----------------------------------------------------------------------------

export function getMatchingCompanies (pattern) {
  return Http.get('/companies/search/' + encodeURIComponent(pattern.trim()));
}

export function getCompanies () {
  return fetchRecordList(RECORD_TYPE.COMPANY);
}

export function getCompany (companyId) {
  return fetchRecord(RECORD_TYPE.COMPANY, companyId);
}

export function getCompanyMapMarkers (search) {
  return Http.post('/company-search/map-markers', search);
}
