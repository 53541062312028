import Http from '@/services/http';
import { RECORD_TYPE } from '@/api/constants';

function getRecordTypeUrl (recordType, urls) {
  if (urls[recordType]) {
    return urls[recordType];
  }
  throw new Error('Wrong record type set: ' + recordType);
}

export function getBookmarks (recordType) {
  const path = getRecordTypeUrl(recordType, {
    [RECORD_TYPE.APPLICANT_BOOKMARK]: '/applicant-bookmarks/bookmarks',
    [RECORD_TYPE.COMPANY_BOOKMARK]: '/company-bookmarks/bookmarks',
    [RECORD_TYPE.APPLICANT]: '/applicant-bookmarks/bookmarks',
    [RECORD_TYPE.COMPANY]: '/company-bookmarks/bookmarks',
  });
  return Http.get(path);
}

export function getBookmarkType (recordType, recordId) {
  const path = getRecordTypeUrl(recordType, {
    [RECORD_TYPE.APPLICANT_BOOKMARK]: `/applicant-bookmarks/bookmark/${recordId}`,
    [RECORD_TYPE.COMPANY_BOOKMARK]: `/company-bookmarks/bookmark/${recordId}`,
    [RECORD_TYPE.APPLICANT]: `/applicant-bookmarks/bookmark/${recordId}`,
    [RECORD_TYPE.COMPANY]: `/company-bookmarks/bookmark/${recordId}`,
  });
  return Http.get(path, {});
}

export function addBookmark (recordType, data) {
  const path = getRecordTypeUrl(recordType, {
    [RECORD_TYPE.APPLICANT_BOOKMARK]: '/applicant-bookmarks/bookmark',
    [RECORD_TYPE.COMPANY_BOOKMARK]: '/company-bookmarks/bookmark',
    [RECORD_TYPE.APPLICANT]: '/applicant-bookmarks/bookmark',
    [RECORD_TYPE.COMPANY]: '/company-bookmarks/bookmark',
  });
  return Http.post(path, data);
}
